import {
	ChangeDetectorRef,
	ComponentRef,
	DestroyRef,
	Directive,
	inject,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Mode } from 'apps/analytics/src/app/general/shared/services/appsettings/mode.enum';
import { MachineService } from 'apps/analytics/src/app/general/shared/services/machine/machine.service';
import { combineLatest, Subscription } from 'rxjs';
import { ErrorMessages } from '../../../enums/error-messages.enum';
import { Machine } from '../../../shared/services/machine/machine';
import { LoadingHandler } from './loading-handler';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { modeFilterFeature } from '../../../../store/mode-filter/mode-filter.state';
import { ProductivitySubMenuUrl } from '../../../shared/enums';
import { AnalyticsSpinnerComponent } from '../../../shared/components/spinner/analytics-spinner.component';
import { EmptyStateComponent } from '@agilox/ui';
import { MachineModel } from '@agilox/common';
import { NotForApplianceComponent } from '../not-for-appliance/not-for-appliance.component';

/**
 * A directive to handle the loading of machines effienctly.
 * For usage of this directive the microsynthax is recommended: https://angular.io/guide/structural-directives
 * example of usage:
 * <div *agiloxAnalyticsLoad="let elem of loadingHandler; let machineNames = machineNames; disableOnComb: true; disableOnSum: true; dataAsArray: true">
 */
@Directive({
	selector: '[agiloxAnalyticsLoad]',
})
export class LoadDirective implements OnInit, OnDestroy {
	private store: Store = inject(Store);
	private destroyRef: DestroyRef = inject(DestroyRef);
	private router: Router = inject(Router);
	private selectedModeState$ = this.store.select(modeFilterFeature.selectMode);
	private selectedCombinedMode$ = this.store.select(modeFilterFeature.selectCombined);

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('agiloxAnalyticsLoadOf') loadingHandler: LoadingHandler<unknown, unknown>;

	/** parsed data from the endpoint */
	data: Array<unknown>;
	/** original data from the endpoint */
	private originalData: Array<unknown> | any;
	private dataSubscription: Subscription;
	private subscriptionExpired: boolean | undefined;

	/** name of all machines, which data was fetched for */
	private machineNames: Array<[string, string]> | undefined;
	/** subscription for get machine details */
	private machineDetailsSubscription: Subscription | undefined;

	/**
	 * is needed for do a type-checking on all select machines
	 * if a machin is appliance, the widgets should be disabled
	 */
	private machines: Array<Machine> | undefined;

	@Input('agiloxAnalyticsLoadDisableOnComb') disableOnComb: boolean = false;

	@Input('agiloxAnalyticsLoadDisableOnSum') disableOnSum: boolean = false;

	/** the data gets passed as an array to the markup (same goes for the machinenames) */
	@Input('agiloxAnalyticsLoadDataAsArray') dataAsArray: boolean = false;

	private componentRef: ComponentRef<unknown>;
	private appsettingsSubscriptions: Array<Subscription>;

	private mode: string = '';

	get isDisabled(): boolean {
		return (
			(this.disableOnComb && this.mode === Mode.comb) ||
			(this.disableOnSum && this.mode === Mode.sum)
		);
	}

	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainerRef: ViewContainerRef,
		private changeDetector: ChangeDetectorRef,
		private appsettings: AppsettingsService,
		private machineService: MachineService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.loadData();
		this.appsettingsSubscriptions = this.appsettings.registerOnWebappSettingChanged({
			next: () => this.loadData(),
		});

		combineLatest([this.selectedModeState$, this.selectedCombinedMode$])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(([mode, combinedMode]) => {
				const isProductivityHistory = this.router.url === ProductivitySubMenuUrl.history;
				this.mode = mode;
				if (isProductivityHistory && combinedMode) {
					this.mode = Mode.comb;
				}
				this.loadData();
			});
	}

	ngOnDestroy() {
		this.componentRef?.destroy();
		this.appsettingsSubscriptions?.forEach((sub) => sub?.unsubscribe());
		this.machineDetailsSubscription?.unsubscribe();
	}

	/**
	 * loads the data from the given loadingHandler
	 * before starting loading, the spinner gets rendered
	 */
	loadData() {
		this.machineNames = null;
		this.data = null;
		this.subscriptionExpired = false;
		this.originalData = null;
		// load spinner or not-avaiable
		this.generateView();

		if (this.isDisabled) {
			return;
		}

		this.machineDetailsSubscription?.unsubscribe();
		const useSigneSelectedMachine =
			this.router.url.includes('battery') && this.appsettings.webAppSettings.selectedMachine;
		this.machineDetailsSubscription = this.machineService.getMachineDetailsBySerial(
			(machines: Machine[]) => {
				this.machineNames = machines.map((machine: Machine) => [machine.serial, machine.name]);
				this.machines = machines;
				this.loaded();
			},
			useSigneSelectedMachine
				? [this.appsettings.webAppSettings.selectedMachine]
				: this.appsettings.webAppSettings.selectedMachines
		);

		this.dataSubscription?.unsubscribe();
		this.dataSubscription = this.loadingHandler.fetchFunction((data: Array<unknown> | string) => {
			this.subscriptionExpired = data === ErrorMessages.NO_MACHINE_SUBSCRIPTION;
			if (typeof data !== 'string') {
				// if no parse-function given, just save the data
				this.originalData = data;
			}
			this.loaded();
		});
	}

	loaded() {
		if (this.hasFailedResponse()) {
			return;
		}

		// Handle loaded state when data is valid and auth was successful
		if (this.shouldProcessData()) {
			this.processData();
			this.generateView();
			return;
		}

		if (this.subscriptionExpired) {
			this.generateView();
		}
	}

	private hasFailedResponse(): boolean {
		return (
			this.originalData &&
			!Array.isArray(this.originalData) &&
			this.originalData.hasOwnProperty('status') &&
			!this.originalData['status']
		);
	}

	private shouldProcessData(): boolean {
		return !this.isDisabled && !!this.originalData && !!this.machineNames && !!this.machines;
	}

	private processData(): void {
		this.data =
			this.loadingHandler.parseFunction?.(this.originalData, this.machineNames) ??
			this.originalData;
		this.loadingHandler.onLoadedFunction?.(this.data);
	}

	generateView() {
		this.viewContainerRef.clear();

		const currentMachine = this.machines?.find(
			(machine: Machine) => machine.serial === this.appsettings.webAppSettings.machineToShow
		);

		const isAppliance =
			this.mode === Mode.each &&
			currentMachine?.machineModel === MachineModel.APPLIANCE.toUpperCase();

		let isThereData: boolean | null = null;
		let isThereDataException = false;

		// Evaluate isThereData safely
		try {
			isThereData = this.loadingHandler?.isThereDataFunction?.(this.data);
		} catch {
			isThereDataException = true;
		}

		// Core logic that decides which component should be rendered.
		if (isAppliance) {
			this.renderComponent(NotForApplianceComponent);
		} else if (this.isDisabled) {
			this.renderEmptyStateForMode();
		} else if (this.subscriptionExpired) {
			this.renderEmptyStateWithMessage('error_messages.no_machine_subscription');
		} else {
			this.renderSpinner(isThereData);
		}

		// Render the main template if conditions are met
		if (this.shouldRenderTemplate(isAppliance, isThereData)) {
			this.renderTemplate();
		}

		this.changeDetector.detectChanges();

		// Handle isThereData exceptions
		if (isThereDataException) {
			throw Error('The isThereDataFunction probably threw an exception');
		}
	}

	private renderComponent(component: any) {
		this.componentRef = this.viewContainerRef.createComponent(component);
		this.componentRef.changeDetectorRef.markForCheck();
	}

	private renderEmptyStateForMode() {
		const component = this.viewContainerRef.createComponent(EmptyStateComponent).instance;

		component.isAbsolute = true;

		if (this.mode === Mode.comb) {
			component.text = this.translateService.instant(
				'widget.general.not_available_for_combined_mode'
			);
		} else if (this.mode === Mode.sum) {
			component.text = this.translateService.instant('widget.general.not_available_for_sum_mode');
		}
	}

	private renderEmptyStateWithMessage(messageKey: string) {
		const component = this.viewContainerRef.createComponent(EmptyStateComponent).instance;

		component.text = this.translateService.instant(messageKey);
		component.isAbsolute = true;
	}

	private renderSpinner(isThereData: boolean | null) {
		const component = this.viewContainerRef.createComponent(AnalyticsSpinnerComponent).instance;

		component.data =
			this.loadingHandler?.isThereDataFunction && isThereData === false ? [] : this.data;

		component.isLoading = true;
	}

	private renderTemplate() {
		const data = !this.dataAsArray && this.disableOnComb ? this.data[0] : this.data;

		const machineNames =
			!this.dataAsArray && this.disableOnComb ? this.machineNames[0] : this.machineNames;

		this.viewContainerRef.createEmbeddedView(this.templateRef, {
			$implicit: data,
			machineNames,
		});
	}

	private shouldRenderTemplate(isAppliance: boolean, isThereData: boolean | null): boolean {
		const hasValidSubscription = !this.subscriptionExpired;
		const isEnabled = !this.isDisabled;
		const hasSelectedMachines =
			this.appsettings.webAppSettings.selectedMachines.length > 0 &&
			!!this.appsettings.webAppSettings.selectedMachine;
		const hasMachineNames = !!this.machineNames;
		const hasData = !this.loadingHandler?.isThereDataFunction && !!this.data?.length;

		return (
			hasValidSubscription &&
			isEnabled &&
			!isAppliance &&
			hasSelectedMachines &&
			hasMachineNames &&
			(hasData || isThereData)
		);
	}
}
