import { environment } from '@analytics/env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartialObserver, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Machine } from './machine';
import { CountriesService, HttpParamsBuilder, Response, Vehicle } from '@agilox/common';

/**
 * fetches, updates and creates machines
 */
@Injectable({
	providedIn: 'root',
})
export class MachineService {
	/**
	 * constructs the service
	 * @param http need for fetching data
	 * @param countryService
	 */
	constructor(
		private http: HttpClient,
		private countryService: CountriesService
	) {}

	getMachineNames(observable: PartialObserver<string[]>, serials: string[]) {
		let params = HttpParamsBuilder.buildParameters(
			{ number: 0, size: serials.length },
			undefined,
			undefined,
			{
				serial: serials.join(','),
			}
		);

		return this.http
			.get<Response<Vehicle>>(environment.server + '/v2/MachineMgmt/vehicles', { params })
			.pipe(
				map((data: any) => {
					let machines = [];
					if (data.status) {
						machines = data.data.map((machine: Vehicle) => machine.name);
					}
					return machines;
				})
			)
			.subscribe(observable);
	}

	getMachineDetailsBySerial(
		observer: (data: Array<Machine>) => void,
		serials: Array<string>
	): Subscription {
		let params = HttpParamsBuilder.buildParameters(
			{ number: 0, size: serials.length },
			undefined,
			undefined,
			{
				serial: serials.join(','),
			}
		);
		return this.http
			.get<Response<Vehicle>>(environment.server + '/v2/MachineMgmt/vehicles', { params })
			.pipe(
				map((data: any) => {
					let machines = [];
					if (data.status) {
						machines = (data.data as Array<any>).map((machine) =>
							Machine.generateFromJson(machine, this.countryService)
						);
					}
					return machines;
				})
			)
			.subscribe(observer);
	}
}
