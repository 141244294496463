import {
	CheckSubscriptionExpireSoonPipe,
	PluralTranslatePipe,
	Response,
	Vehicle,
} from '@agilox/common';
import { NotifyComponent } from '@agilox/ui';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { vehicleFilterFeature } from 'apps/analytics/src/app/store/vehicles-filter/vehicles-filter.state';
import { filter, map, Observable, switchMap } from 'rxjs';
import { GetEarliestExpireDatePipe } from '../../pipes/get-earliest-expire-date.pipe';
import { AppsettingsService } from '../../services/appsettings/appsettings.service';
import { SubscriptionVehicleDisplayPipe } from './data/pipes/subscription-vehicle-display.pipe';
import { SubscriptionExpireSoonService } from './data/services/subscription-expire-soon.service';

@Component({
	selector: 'agilox-analytics-subscription-expire-soon-notify',
	templateUrl: './subscription-expire-soon-notify.component.html',
	standalone: true,
	imports: [
		CommonModule,
		NotifyComponent,
		PluralTranslatePipe,
		SubscriptionVehicleDisplayPipe,
		GetEarliestExpireDatePipe,
	],
	providers: [SubscriptionExpireSoonService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionExpireSoonNotifyComponent {
	private store = inject(Store);
	private service = inject(SubscriptionExpireSoonService);
	public appsettingsService = inject(AppsettingsService);

	public expireSoonVehicles$: Observable<Vehicle[]> = this.store
		.select(vehicleFilterFeature.selectSelectedVehicles)
		.pipe(
			filter((selectedVehicles) => !!selectedVehicles && selectedVehicles.length > 0),
			switchMap((selectedVehicles: string[]) =>
				this.service.fetchVehicles(selectedVehicles).pipe(
					map((data: Response<Vehicle>) =>
						(data.data as Vehicle[]).filter((vehicle) => selectedVehicles.includes(vehicle.serial))
					),
					map((data: Vehicle[]) =>
						data.filter((vehicle: Vehicle) =>
							new CheckSubscriptionExpireSoonPipe().transform(vehicle)
						)
					)
				)
			)
		);
}
