import { DateSelector } from './date-selector.interface';
import { WidgetConfig } from './widget-config';

export class AppSettings {
	/**
	 * constructs an Appsettings class
	 * @param dateSelector date selector
	 * @param dashboardWidgetConfig dashboard widget config
	 * @param selectedMachines selected machines
	 * @param selectedMachine
	 * @param machineToShow machine to show
	 * @param onboarding onboarding
	 */
	constructor(
		public dateSelector: DateSelector,
		public dashboardWidgetConfig: Array<WidgetConfig>,
		public selectedMachines: Array<string>,
		public selectedMachine: string,
		public machineToShow: string,
		public onboarding: {
			myWatched: boolean;
			analyticsWatched: boolean;
		}
	) {}

	/**
	 * generates appsettings from json
	 * @param data data
	 */
	static generateFromJson(data: any): AppSettings {
		return new AppSettings(
			data.dateSelector,
			data.dashboardWidgetConfig,
			data.selectedMachines,
			data.selectedMachine,
			data.machineToShow,
			data.onboarding
		);
	}

	/**
	 * appsettings to json
	 */
	toJson() {
		return {
			dateSelector: this.dateSelector,
			dashboardWidgetConfig: this.dashboardWidgetConfig,
			selectedMachines: this.selectedMachines,
			selectedMachine: this.selectedMachine,
			machineToShow: this.machineToShow,
			onboarding: this.onboarding,
		};
	}
}
