import { Vehicle } from '@agilox/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Machine } from '../services/machine/machine';

@Pipe({
	name: 'getEarliestExpireDate',
	standalone: true,
})
export class GetEarliestExpireDatePipe implements PipeTransform {
	transform(vehicles: Vehicle[] | Machine[]): number | undefined {
		if (!vehicles || vehicles.length === 0) {
			return undefined;
		}

		const dates = vehicles.map((vehicle: Vehicle | Machine) => {
			// TODO: Remove once subscription expired notify is refactored
			if (vehicle instanceof Machine) {
				return (vehicle as Machine).endDate;
			}
			return vehicle.subscription_end * 1000;
		});

		const earliestDate = Math.min(...dates);

		return earliestDate;
	}
}
