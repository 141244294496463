<ui-notify
	class="subscription-expired-notify"
	data-cy="subscription-expired-notify"
	*ngIf="notifyVisible"
	[text]="
		subscriptionExpiredMachines.length
			| pluralTranslate
				: 'general.subscription_for_machine_expired'
				: 'general.subscription_for_machines_expired'
				: {
						machines: expiredMachinesText,
						date: subscriptionExpiredMachines | getEarliestExpireDate | date: 'shortDate',
				  }
	"
	type="warning"
></ui-notify>
