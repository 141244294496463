import { SelectOption } from '@agilox/ui-common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppsettingsService } from 'apps/analytics/src/app/general/shared/services/appsettings/appsettings.service';
import { Mode } from 'apps/analytics/src/app/general/shared/services/appsettings/mode.enum';
import { WidgetConfig } from 'apps/analytics/src/app/general/shared/services/appsettings/widget-config';
import { WidgetService } from 'apps/analytics/src/app/general/widgets/widget.service';
import { Subscription } from 'rxjs';
import { SwitchChart } from '../switch-chart.interface';

/**
 * This component displays the header of a widget. It gets used in the components which are display inside
 * of a widget (so it's not used in app-widget itself).
 *
 * The header can be altered (adding own icons) through using ng-content. The title gets set via the
 * according input
 */
@Component({
	selector: 'agilox-analytics-widget-header',
	templateUrl: './widget-header.component.html',
	styleUrls: ['./widget-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetHeaderComponent implements OnInit, OnDestroy {
	/**
	 * Uses the title of a widget which needs to be defined in the widget-configs in the widget-service
	 */
	title: string;

	@Input() disableOnComb: boolean | undefined;

	@Input() disableOnSum: boolean | undefined;

	@Input() disabledOnLoadNoData: boolean | undefined;

	@Input() disableOnLoad: boolean | undefined;

	@Input() beta: boolean = false;

	/** name of the widget */
	@Input() set widgetName(val: string) {
		this._widgetName = val;
		this.title = 'widget.' + val + '.title';
	}

	get widgetName(): string {
		return this._widgetName;
	}

	_widgetName: string | undefined;

	/** if icons are open on mobile */
	iconsOpenMobile: boolean = false;

	/** if set to true then there will be a toggle for displaying line-charts as bar-charts */
	@Input() chartSwitch: boolean = false;

	@Input() barChart: boolean = false;

	@Output() barChartChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() switchChart: SwitchChart = {
		rightChartConfig: { name: 'chart_bar' },
		leftChartConfig: { name: 'chart_line' },
		currentMode: false,
	};

	/** config of the widget, which gets fetched from the widget-service */
	widgetConfig: WidgetConfig | undefined;

	/**
	 * subscription for the mode
	 */
	private modeChangeSubscription: Subscription | undefined;
	private modeChangeObserver = {
		next: () => this.changeDetection.detectChanges(),
	};

	/** subscription for getNames of the machines */
	private getMachineNamesSubscription: Subscription | undefined;

	/** is the widget pinned to the dashboard */
	isWidgetPinnedToDashboard: boolean | undefined;

	mode = Mode;

	/**
	 * settings variables, fetching machinename, setting renderedInShowAllPopup
	 * @param widgetService
	 * @param appsettings
	 * @param changeDetection
	 * @param translateService
	 * @param selectorService
	 */
	constructor(
		private widgetService: WidgetService,
		public appsettings: AppsettingsService,
		private changeDetection: ChangeDetectorRef,
		private translateService: TranslateService
	) {
		this.modeChangeSubscription = this.appsettings.registerOnModeChanged(this.modeChangeObserver);
	}

	/**
	 * fetching widgetConfig, setting isWidgetPinnedToDashboard
	 */
	ngOnInit() {
		this.widgetConfig = this.widgetService.getWidgetConfigByName(this.widgetName);
		this.isWidgetPinnedToDashboard = this.widgetService.isWidgetPinned(this.widgetConfig?.name);
		this.switchChart.currentMode = this.barChart;
	}

	/**
	 * unsubscribing from all subscriptions
	 */
	ngOnDestroy() {
		this.getMachineNamesSubscription?.unsubscribe();
		this.modeChangeSubscription?.unsubscribe();
	}

	/**
	 * toggles widget on dashboard
	 */
	toggleOnDashboard() {
		const timeoutId = setTimeout(() => {
			this.widgetService.toggleWidgetOnDashboard(this.widgetName);
			this.isWidgetPinnedToDashboard = !this.isWidgetPinnedToDashboard;
			this.changeDetection.detectChanges();
			clearTimeout(timeoutId);
		}, 0);
	}

	/**
	 * toggles help
	 */
	toggleHelp() {
		this.widgetService.setHelpText(
			this.translateService.instant('widget.' + this.widgetConfig.name + '.tooltip')
		);
	}

	/**
	 * gets invoked from the binary-toggler
	 * @param val value of the barchart toggler
	 */
	barChartSwitchToggled(val: boolean) {
		this.barChart = val;
		this.barChartChange.emit(this.barChart);
	}

	/**
	 * checks whether the export is disabled or the pin and the help
	 * @param checkDisabledOnLoad if check for disabled
	 */
	isDisabled(checkDisabledOnLoad: boolean): boolean {
		if (checkDisabledOnLoad) {
			return (
				!(this.disableOnComb && this.appsettings.dateSelector.mode === Mode.comb) &&
				!(this.disableOnSum && this.appsettings.dateSelector.mode === Mode.sum) &&
				this.disableOnLoad
			);
		} else {
			return (
				(this.disableOnComb && this.appsettings.dateSelector.mode === Mode.comb) ||
				(this.disableOnSum && this.appsettings.dateSelector.mode === Mode.sum) ||
				!this.disabledOnLoadNoData
			);
		}
	}

	@HostListener('document:click', ['$event'])
	toggleIcons(evt: Event, onTarget = false) {
		this.iconsOpenMobile = onTarget;
		if (this.iconsOpenMobile) {
			evt.stopImmediatePropagation();
		}
	}
}
