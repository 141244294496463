import {
	App,
	AppMenuPoints,
	AppNavMenuPointsService,
	ErrorService,
	ErrorType,
	MenuPointService,
	NavVariant,
	Role,
	User,
} from '@agilox/common';
import { AppMenuPoint, MenuPoint } from '@agilox/ui-common';
import { environment } from '@analytics/env/environment';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'apps/analytics/src/app/general/shared/services/authentication/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, shareReplay } from 'rxjs';
import { filter } from 'rxjs/operators';
import { analyticsOnboarding } from './general/constants/analytics-onboarding.const';
import { MenuPointUrl } from './general/shared/enums';
import { AppsettingsService } from './general/shared/services/appsettings/appsettings.service';
import { analyticsMenuPoints } from './general/shared/services/authentication/analytics-menupoints';
import { initAppSettings } from './store/app-settings.actions';

@Component({
	selector: 'agilox-analytics-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	private destroyRef = inject(DestroyRef);

	title = 'AGILOX Analytics';

	displayMode: boolean = false;

	pageName: string;
	// do not delete. Is used in the markup, because you cant access enums directly
	roles = Role;

	@ViewChild('body', { static: true }) body: ElementRef<HTMLDivElement>;

	desktopMenuPoints: Array<MenuPoint>;
	mobileMenuPoints: Array<MenuPoint>;
	appNavMenuPoints: Array<AppMenuPoint>;
	activeApp: AppMenuPoint | undefined = AppMenuPoints.find((app) => app.name === 'analytics');
	error$ = this.errorService.error$;

	user: User;

	helpOpen: boolean = false;

	private _routerEvents$: Observable<any> = this.router.events.pipe(
		takeUntilDestroyed(),
		shareReplay(1)
	);
	private _navigationEnd$: Observable<NavigationEnd> = this._routerEvents$.pipe(
		filter((event) => event instanceof NavigationEnd)
	);

	/**
	 * Will get triggered when a navigation chunk fails to load because the user is offline
	 * @private
	 */
	private _navigationChunkLoadError$: Observable<NavigationError> = this._routerEvents$.pipe(
		filter(
			(event) =>
				event instanceof NavigationError &&
				event.error['name'] === 'ChunkLoadError' &&
				!navigator.onLine
		)
	);

	onBoardingWatched = this.appsettings.onBoardingWatchedSignal;
	public readonly analyticsOnboarding = analyticsOnboarding;

	/**
	 * @param translate needed for setting default lang
	 * @param appsettings
	 * @param authenticate needed for display the right menu
	 * @param router
	 * @param errorService
	 * @param deviceDetectorService
	 * @param menuPointService
	 * @param appNavMenuPointsService
	 * @param activatedRoute
	 * @param cdr
	 * @param store
	 */
	constructor(
		private translate: TranslateService,
		private appsettings: AppsettingsService,
		public authenticate: AuthenticationService,
		public router: Router,
		private errorService: ErrorService,
		private deviceDetectorService: DeviceDetectorService,
		private menuPointService: MenuPointService,
		private appNavMenuPointsService: AppNavMenuPointsService,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private store: Store
	) {
		if (!this.appsettings.user) {
			if (['de', 'de-AT', 'de-DE', 'de-CH'].includes(navigator.language)) {
				this.translate.use('de');
			} else {
				this.translate.use('en');
			}
		} else {
			this.desktopMenuPoints = this.menuPointService.getMenuPointsByCategory(
				NavVariant.DESKTOP,
				this.appsettings.user.role
			);
			this.mobileMenuPoints = this.menuPointService.getMenuPointsByCategory(
				NavVariant.MOBILE,
				this.appsettings.user.role
			);
			this.user = this.appsettings.user;
			this.appNavMenuPoints = this.appNavMenuPointsService.getAppNavMenuPoints(
				this.user?.role as Role
			);
		}
		this._navigationEnd$.subscribe((event) => {
			this.pageName =
				'p-' + event.urlAfterRedirects.replace('/', '').replace(/\//g, '-').split('?')[0];
			if (document.cookie.includes('statisticCookies=1') && environment.production) {
				this.setGoogleAnalyticsDimension();
			}
		});

		this._navigationChunkLoadError$.subscribe((event) => {
			this.errorService.setError({
				application: App.analytics,
				type: ErrorType.NO_INTERNET_ERROR,
			});
		});

		this.activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
			this.displayMode =
				params['displayMode'] === 'true' &&
				this.deviceDetectorService.isDesktop() &&
				this.router.url.includes(MenuPointUrl.dashboard);
		});
	}

	private setGoogleAnalyticsDimension() {
		const gaDimension = this.appsettings.getUserRole();
		(window as any).dataLayer = (window as any).dataLayer || [];
		(window as any).dataLayer.push({
			event: 'page_view',
			dimension: gaDimension,
			page_path: this.router.url,
		});
	}

	ngAfterViewInit() {
		this.store.dispatch(initAppSettings());
		// ios fix
		if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
			const doc = document.documentElement;
			doc.style.height = `${window.innerHeight}px`;
		}
	}

	setOnboardingToWatched() {
		this.appsettings.markAnalyticsOnboardingAsWatched().subscribe((_) => {
			this.onBoardingWatched.set(true);
			this.cdr.markForCheck();
		});
	}

	navCallback(menuPoint: MenuPoint): void {
		if (menuPoint.name === analyticsMenuPoints.help.name) {
			this.helpOpen = true;
			return;
		}

		if (menuPoint.name === analyticsMenuPoints.logout.name) {
			this.logout();
			return;
		}
	}

	logout() {
		this.authenticate.logout();
	}

	onClose() {
		this.errorService.clearError();
	}

	onCloseHelp() {
		this.helpOpen = false;
	}
}
